import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import rehypeReact from "rehype-react";

import {Layout} from '../components/layout';
import PostMeta from "../components/post-meta";
import CommentBox from "../components/comments";
import {Github} from 'styled-icons/fa-brands';
import styled from "styled-components";

import '../styles/blog-post.css';

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

export function PostHr({ className, children }) {
  return (
      <div className={`post-hr ${className || ""}`}>
        {children}
      </div>
  );
}

const DraftHeader = styled.div`
  width: 100%;
  text-align: center;
  border: 20px solid #f4bf42;
  border-image: repeating-linear-gradient(
    -45deg,
    #f4bf42,
    #f4bf42 10px,
    #30250c 10px,
    #30250c 20px
  ) 25;
  padding: 1rem;
  margin-bottom: 0.5rem;
`;

export default function Template({ data }) {
  const { markdownRemark: post } = data;
  const { repo, title, date, date_modified, tags, custom_hr, published } = post.frontmatter;
  const { readingTime } = post.fields;
  const draft = !published;
  return (
    <Layout>
    <div>
      <Helmet title={`${title} - beardypig`}>
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.0/css/solid.css"
              integrity="sha384-COsgLGwf6vbsibKzWojSqhIjQND/Sa0RWQ5BHFrKOz5JrUObnh5GEBUH2oZwITuM" crossOrigin="anonymous" />
        <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.6.0/css/fontawesome.css"
              integrity="sha384-J4287OME5B0yzlP80TtfccOBwJJt6xiO2KS14V7z0mVCRwpz+71z7lwP4yoFbTnD" crossOrigin="anonymous" />
      </Helmet>
      <div className="blog-post">
        <PostHr className={custom_hr}>
          <h1>{title}
            {repo &&
            <>
            <a href={`https://www.github.com/${repo}`} target="_blank" rel="noopener noreferrer">
              <Github style={{marginLeft: "0.4rem", marginBottom: "0.5rem"}} size="1rem"/>
            </a>
            </>}
          </h1>
          <PostMeta date={date}
                    date_modified={date_modified}
                    tags={tags.split(" ")}
                    readingTime={readingTime.text} />
        </PostHr>
        {draft &&
          <DraftHeader>
            <p style={{marginBottom: "0"}}>This page is a <strong>draft</strong>.</p>
          </DraftHeader>
        }
        { renderAst(post.htmlAst) }
      </div>
      <CommentBox/>
    </div>
    </Layout>
  );
}

export const pageQuery = graphql`
  query BlogPostByPath($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      htmlAst
      fields {
        slug
        readingTime {
          text
        }
      }
      frontmatter {
        date
        date_modified
        title
        tags
        custom_hr
        repo
        published
      }
    }
  }
`;